<template>
    <b-form-row class="ml-n1 mr-n1">
        <b-col cols="12">
            <label class="d-block">{{ label }}</label></b-col
        >

        <b-col cols>
            <b-form-group
                :invalid-feedback="null"
                label-for="year"
                :class="['mw-72px', {'mb-0': isRangeInvalid}]"
            >
                <b-form-input
                    required
                    id="year"
                    inputmode="numeric"
                    class="custom-input"
                    autocomplete="off"
                    :placeholder="t('year')"
                    :state="(state || !!model.year) && yearState && rangeState"
                    :disabled="disabled"
                    :formatter="(val, $event) => formatInput(val, $event, 4, false)"
                    v-model.number="model.year"
                    @input="(val) => onChange(val, 'setFullYear')"
                ></b-form-input>
                <b-tooltip triggers="focus" target="year" placement="bottom">
                    {{ t('year') }}
                    <b-icon-check class="text-success ml-2" scale="2" />
                </b-tooltip>
            </b-form-group>
        </b-col>
        <b-col cols>
            <b-form-group
                :invalid-feedback="null"
                label-for="month"
                :class="['mw-56px', {'mb-0': isRangeInvalid}]"
            >
                <b-form-input
                    required
                    id="month"
                    inputmode="numeric"
                    class="custom-input"
                    autocomplete="off"
                    :placeholder="t('month')"
                    :state="(state || !!model.month) && monthState && rangeState"
                    :disabled="!yearState || disabled"
                    :formatter="formatInput"
                    v-model.number="model.month"
                    @input="(val) => onChange(val > 0 ? val - 1 : val, 'setMonth')"
                ></b-form-input>
                <b-tooltip triggers="focus" target="month" placement="bottom">
                    {{ t('month') }}
                    <b-icon-check class="text-success ml-2" scale="2" />
                </b-tooltip>
            </b-form-group>
        </b-col>
        <b-col cols>
            <b-form-group
                :invalid-feedback="null"
                label-for="day"
                :class="['mw-56px', {'mb-0': isRangeInvalid}]"
            >
                <b-form-input
                    required
                    id="day"
                    inputmode="numeric"
                    class="custom-input"
                    autocomplete="off"
                    :placeholder="t('day')"
                    :disabled="!yearState || !monthState || disabled"
                    :state="(state || !!model.day) && dayState && rangeState"
                    :formatter="formatInput"
                    v-model.number="model.day"
                    @input="(val) => onChange(val, 'setDate')"
                ></b-form-input>
                <b-tooltip triggers="focus" target="day" placement="bottom">
                    {{ t('day') }}
                    <b-icon-check class="text-success ml-2" scale="2" />
                </b-tooltip>
            </b-form-group>
        </b-col>

        <b-col cols="12" v-if="isRangeInvalid">
            <div class="d-block invalid-feedback">{{ t('dateOutOfRange') }}</div></b-col
        >
    </b-form-row>
</template>

<script>
import debounce from 'lodash/debounce';

const DEFAULT_MODEL = () => ({
    year: null,
    month: null,
    day: null,
});

export default {
    name: 'InputsDatePicker',
    props: {
        label: {
            type: String,
            default: () => null,
        },
        value: {
            type: [Date, String],
            default: () => null,
        },
        maxDate: {
            type: [Date, String],
            default: () => null,
        },
        minDate: {
            type: [Date, String],
            default: () => null,
        },
        state: {
            type: Boolean,
            default: () => true,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            date: new Date(),
            max: null,
            min: null,
            model: DEFAULT_MODEL(),
        };
    },

    watch: {
        value: {
            immediate: true,
            handler: function (value) {
                this.parseValue(value);
            },
        },
        maxDate: {
            immediate: true,
            handler: function (value) {
                if (!value) return;
                this.max = typeof value == 'string' ? new Date(value) : value;
            },
        },
        minDate: {
            immediate: true,
            handler: function (value) {
                if (!value) return;
                this.min = typeof value == 'string' ? new Date(value) : value;
            },
        },
    },

    computed: {
        isRangeInvalid() {
            // rangeState can be true, false or null... Consider invalid when is false
            return this.rangeState == false;
        },
        rangeState() {
            if (!this.value || this.disabled) return null;
            const date = new Date();
            date.setFullYear(this.model.year);
            date.setMonth(this.model.month - 1);
            date.setDate(this.model.day);
            if (this.min && date < this.min) return false;
            else if (this.max && date > this.max) return false;
            return true;
        },
        yearState() {
            const yearLength = this.model.year?.toString().length;
            if (
                (!this.value && !this.model.year) ||
                !this.max ||
                !this.min ||
                yearLength != 4 ||
                this.disabled
            )
                return null;
            const yearState =
                this.model.year <= this.max.getFullYear() &&
                this.model.year >= this.min.getFullYear();
            return yearState;
        },
        monthState() {
            if ((!this.value && !this.model.month) || this.disabled) return null;
            const monthState = this.model.month <= 12 && this.model.month >= 1;
            return monthState;
        },
        dayState() {
            if ((!this.value && !this.model.day) || this.disabled) return null;
            const daysInCurrentMonth = new Date(this.model.year, this.model.month, 0).getDate();
            const dayState = this.model.day <= daysInCurrentMonth && this.model.day >= 1;
            return dayState;
        },
        isValid() {
            return this.yearState && this.monthState && this.dayState && !this.isRangeInvalid;
        },
    },

    methods: {
        /** Parse a date string or object, and set the model state */
        parseValue(value) {
            if (!value) {
                this.model = DEFAULT_MODEL();
                return;
            }
            this.date =
                typeof this.value == 'string'
                    ? new Date(this.value)
                    : new Date(this.value.getTime());
            this.model.year = this.date.getFullYear() || null;
            this.model.month = (!isNaN(this.date.getMonth()) && this.date.getMonth() + 1) || null;
            this.model.day = this.date.getDate() || null;

            //putting values with right formatter
            if (this.model.month) {
                this.model.month = this.formatInput(this.model.month.toString());
            }
            if (this.model.day) {
                this.model.day = this.formatInput(this.model.day.toString());
            }
        },

        /** Formatter for numeric input fields */
        formatInput(value, event, maxLength = 2, padFormatting = true) {
            // Removing no number character
            const regex = new RegExp(/[^0-9]/g);
            const formatted = value.replace(regex, '').substring(0, maxLength);

            /** After the new value is set to the input, default browser behavior is moving the cursor at the end.
             * We need to ensure cursor keep its position and to achieve it we need to place that logic in the callback queue
             * since in the call stack we need to return the new value to allow the render queue move the cursor to the new position.
             * Note: Bootstrap-vue page knows about the necessity but do not offer a elegant way to solve it.
             * Go to https://bootstrap-vue.org/docs/components/form-input#formatter-support last note section
             * Only trigger curso positioning when event is type InputEvent, safari browser get freeze otherwise
             */
            if (event instanceof InputEvent) {
                const {
                    target: {selectionEnd, selectionStart},
                } = event;
                setTimeout(() => {
                    event.target.selectionStart = selectionStart;
                    event.target.selectionEnd = selectionEnd;
                });
                // When delete Backward or Forward whe need to avoid padStart
                if (event.inputType.includes('delete')) return formatted;
            }
            // Execute padStart if padFormatting param and formatted have value and current input value do not have non numerical chars
            return padFormatting && formatted && !regex.test(value)
                ? formatted.padStart(maxLength, 0)
                : formatted;
        },

        onChange: debounce(function (value, setter) {
            this.date.setFullYear(this.model.year || null);
            this.date.setMonth((this.model.month && this.model.month - 1) || null);
            this.date.setDate(this.model.day || null);
            /* we need to trigger change to binding value only if is valid */
            if (this.isValid && !isNaN(parseInt(value))) {
                this.$emit('input', new Date(this.date.getTime()));
            }
        }, 1000),

        /** Re-parse the date string/object to reset the inputs */
        reset() {
            this.parseValue(this.value);
        },
    },
};
</script>
<style scoped>
.form-control.is-valid,
.form-control.is-invalid {
    padding-right: calc(0.75em + 1rem) !important; /* bootstrap override without it */
}
.mw-56px {
    min-width: 56px;
}
.mw-72px {
    min-width: 72px;
}
</style>
